<template>
  <v-container>
    <FilterOrder />
    <v-pagination
      v-if="!isLength"
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
      depressed
    ></v-pagination>
    <v-data-table
      dense
      :headers="headers"
      :items="orderList"
      item-key="name"
      disable-filtering
      disable-sort
      class="elevation-0"
      hide-default-header
      hide-default-footer
    >
      <template v-slot:header="{ props }" style="min-height: 50px">
        <th class="custom-th text-center">
          {{ props.headers[0].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 120px">
          {{ props.headers[1].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 80px">
          {{ props.headers[2].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 120px">
          {{ props.headers[3].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 80px">
          {{ props.headers[4].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[5].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[6].text }}
        </th>
        <th class="custom-th text-center" style="min-width: 100px">
          {{ props.headers[7].text }}
        </th>
      </template>

      <!-- REDIRECT TO ORDER DETAIL -->
      <template v-slot:[`item.order_no`]="{ item }">
        <a href="" @click.prevent="toOrderDetail(item.id)">{{ item.order_no }}</a>
      </template>

      <!-- REDIRECT TO CUSTOMER DETAIL -->
      <template v-slot:[`item.customer_name`]="{ item }">
        <a href="" @click.prevent="toCustomerDetail(item)">{{ item.customer_name }}</a>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <a class="mr-4" href="" @click.prevent="toDetail(item.id)">{{
          $t("message.detail")
        }}</a>
        <v-btn
          small
          style="margin-right: 5px"
          depressed
          color="primary"
          @click="printOrder(1, item.id)"
        >
          <v-icon left>
            mdi-printer-outline
          </v-icon>
          {{ $t("message.print-invoice") }}
        </v-btn>
        <v-btn small depressed color="primary" @click="printOrder(2, item.id)">
          <v-icon left>
            mdi-printer-outline
          </v-icon>
          {{ $t("message.print-receipt") }}
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import FilterOrder from "@/components/FilterOrder.vue";
import { mdiMagnify } from "@mdi/js";
import moneyFormat from "../helpers/moneyFormat";

export default {
  name: "Orders",
  components: {
    FilterOrder,
  },
  data: () => ({
    iconSearch: mdiMagnify,
    option: {},
    page: 1,
  }),
  watch: {
    orderList: {
      handler() {
        this.page = parseInt(this.$store.state.order_module.page);
      },
      deep: true,
    },
  },
  computed: {
    isLength() {
      return this.$store.state.order_module.is_length;
    },
    lengthPage() {
      return this.$store.state.order_module.length_page;
    },
    orderList() {
      let orderData = this.$store.state.order_module.orderListResult;
      if (orderData) {
        orderData.map((el) => {
          el.total_order_price = moneyFormat(parseInt(el.total_order_price));
          // el.balance_amount = moneyFormat(parseInt(el.balance_amount))
          if(el.payout_amount != null) {
            el.payout_amount = moneyFormat(parseInt(el.payout_amount))
          }
        });
      }
      return orderData;
    },
    itemTotal() {
      let resp = this.$store.state.order_module.orderListHeader;
      if (resp.total === undefined) {
        return 0;
      }
      return resp.total;
    },
    itemPerPage() {
      let resp = this.$store.state.order_module.orderListHeader;
      if (resp.per_page === undefined) {
        return 1;
      }
      return resp.per_page;
    },
    currentPage() {
      let resp = this.$store.state.order_module.orderListHeader;
      if (resp.current_page === undefined) {
        return 1;
      }
      return resp.current_page;
    },
    headers() {
      return [
        {
          text: this.$t("message.order-code"),
          align: "start",
          value: "order_no",
        },
        { text: this.$t("message.customer-name"), value: "customer_name", },
        { text: this.$t("message.amount"), value: "total_order_price" },
        {
          text: this.$t("message.status_params"),
          align: "center",
          value: "status"
        },
        {
          text: this.$t("message.order_type_label"),
          align: "center",
          value: "order_type_label"
        },
        {
          text: this.$t("message.datetime"),
          value: "created_at",
          align: "center",
          width: "5%",
        },
        { text: this.$t("message.label-delivery-date"), value: "delivery_date", align: "center", width: "5%" },
        // { text: this.$t("message.status"), value: "status" },
        { text: this.$t("message.action"),align: 'center', value: "actions" },
        // {
        //   text: this.$t("message.print"),
        //   value: "print",
        //   align: "center",
        //   padding: "0px",
        //   width: "15%",
        // },
        // { text: this.$t("message.status"), value: "status" },
        // { text: this.$t("message.action"), value: "actions" },
        // { text: this.$t("message.print-invoice"), value: "print" },
      ];
    },
  },
  created() {
    this.dispatchOrderList();
  },
  methods: {
    toOrderDetail(order_id) {
      this.$router.push(`/order/${order_id}`)
    },
    toCustomerDetail(order) {
      this.$router.push(`/customer/detail/${order.customer_id}`);
    },
    printOrder(type, orderId) {
      this.$store.dispatch("order_module/printOrder", {
        type: type,
        orderId: orderId,
      });
    },
    changePage(event) {
      return this.$store.dispatch("order_module/fetchOrderList", {
        page: event,
      });
    },
    toDetail(id) {
      this.$router.push(`order/${id}`);
    },
    dispatchOrderList() {
      let pageInit = parseInt(this.$store.state.order_module.page);
      this.page = parseInt(this.$store.state.order_module.page);
      return this.$store.dispatch("order_module/fetchOrderList", {
        page: pageInit,
      });
    },
  },
};
</script>

<style scoped>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
.v-data-footer-top {
  border-top: none !important;
}

thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}

.v-data-footer-top {
  border-top: none !important;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
</style>
