var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('FilterOrder'),(!_vm.isLength)?_c('v-pagination',{attrs:{"length":_vm.lengthPage,"total-visible":7,"depressed":""},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.orderList,"item-key":"name","disable-filtering":"","disable-sort":"","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('th',{staticClass:"custom-th text-center"},[_vm._v(" "+_vm._s(props.headers[0].text)+" ")]),_c('th',{staticClass:"custom-th text-center",staticStyle:{"min-width":"120px"}},[_vm._v(" "+_vm._s(props.headers[1].text)+" ")]),_c('th',{staticClass:"custom-th text-center",staticStyle:{"min-width":"80px"}},[_vm._v(" "+_vm._s(props.headers[2].text)+" ")]),_c('th',{staticClass:"custom-th text-center",staticStyle:{"min-width":"120px"}},[_vm._v(" "+_vm._s(props.headers[3].text)+" ")]),_c('th',{staticClass:"custom-th text-center",staticStyle:{"min-width":"80px"}},[_vm._v(" "+_vm._s(props.headers[4].text)+" ")]),_c('th',{staticClass:"custom-th text-center",staticStyle:{"min-width":"100px"}},[_vm._v(" "+_vm._s(props.headers[5].text)+" ")]),_c('th',{staticClass:"custom-th text-center",staticStyle:{"min-width":"100px"}},[_vm._v(" "+_vm._s(props.headers[6].text)+" ")]),_c('th',{staticClass:"custom-th text-center",staticStyle:{"min-width":"100px"}},[_vm._v(" "+_vm._s(props.headers[7].text)+" ")])]}},{key:"item.order_no",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.toOrderDetail(item.id)}}},[_vm._v(_vm._s(item.order_no))])]}},{key:"item.customer_name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.toCustomerDetail(item)}}},[_vm._v(_vm._s(item.customer_name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"mr-4",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.toDetail(item.id)}}},[_vm._v(_vm._s(_vm.$t("message.detail")))]),_c('v-btn',{staticStyle:{"margin-right":"5px"},attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.printOrder(1, item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-printer-outline ")]),_vm._v(" "+_vm._s(_vm.$t("message.print-invoice"))+" ")],1),_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.printOrder(2, item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-printer-outline ")]),_vm._v(" "+_vm._s(_vm.$t("message.print-receipt"))+" ")],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }