<template>
  <div>
    <h4 class="mb-5">{{ $t("message.order-management-page-title") }}</h4>
    <v-form @submit.prevent="submitFilter">
      <v-row no-gutters align="center" justify="space-between">
        <v-col cols="12" md="10">
          <v-row>
            <v-col cols="12" md="3" xs="6">
              <span>{{ $t("message.order-code") }}</span>
              <v-text-field
                clearable
                class="mt-1 font-size filter-input-class"
                v-model="filter.orderNum"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" xs="4">
              <span>{{ $t("message.amount") }}</span>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    type="number"
                    class="mt-1 font-size filter-input-class"
                    v-model="filter.amountFrom"
                    outlined
                    clearable
                  />
                </v-col>
                <v-col class="minus-amount">
                  <v-icon class="icon-tilde-class">mdi-tilde</v-icon>
                </v-col>
                <v-col>
                  <v-text-field
                    type="number"
                    class="mt-1 font-size filter-input-class"
                    v-model="filter.amountTo"
                    outlined
                    clearable
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" xs="6">
              <span>{{ $t("message.filter-date") }}</span>
              <v-row no-gutters class="row-amount">
                <v-col>
                  <v-dialog
                    ref="dialogDateFrom"
                    v-model="modal"
                    :return-value.sync="filter.dateFrom"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.dateFrom"
                        outlined
                        class="mt-1 font-size filter-input-class"
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.dateFrom" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        {{ $t("message.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogDateFrom.save(filter.dateFrom)"
                      >
                        {{ $t("message.ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col class="minus-amount">
                  <v-icon class="icon-tilde-class">mdi-tilde</v-icon>
                </v-col>

                <v-col>
                  <v-dialog
                    ref="dialog"
                    v-model="modalDateTo"
                    :return-value.sync="filter.dateTo"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.dateTo"
                        class="mt-1 font-size filter-input-class"
                        outlined
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.dateTo" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalDateTo = false">
                        {{ $t("message.cancel") }}
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(filter.dateTo)">
                        {{ $t("message.ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" xs="6">
              <span>{{ $t("message.status") }}</span>
                <v-select
                  class="mt-1 font-size filter-input-class"
                  v-model="filter.select"
                  :items="statusItems"
                  item-text="text"
                  item-value="id"
                  outlined
                  clearable
                  return-object
                  single-line
                ></v-select>
            </v-col>
            <v-col cols="12" md="4" xs="6">
              <span>{{ $t("message.label-delivery-date") }}</span>
              <v-dialog
                ref="dialogDelivery_date"
                v-model="modalDelivery_date"
                :return-value.sync="filter.elivery_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.delivery_date"
                    outlined
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    class="mt-1 font-size filter-input-class"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filter.delivery_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalDelivery_date = false">
                    {{ $t("message.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogDelivery_date.save(filter.delivery_date)"
                  >
                    {{ $t("message.ok") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="" cols="12" md="1" xs="6">
          <v-btn type="submit" @click.prevent="submitFilter" rounded color="primary">
            <v-icon left>
              {{ iconSearch }}
            </v-icon>
            {{ $t("message.search") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import { mdiTilde } from "@mdi/js";

export default {
  name: "FilterOrder",
  data: () => ({
    iconSearch: mdiMagnify,
    iconTilde: mdiTilde,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    filter: {
      select: {},
      orderNum: "",
      delivery_date: "",
      amountFrom: "",
      amountTo: "",
      dateFrom: "",
      dateTo: "",
    },
    modalDateFrom: false,
    modalDateTo: false,
    date: "",
    modal: "",
    modalDelivery_date: ""
  }),
  methods: {
    submitFilter() {
      if (!this.filter.orderNum) this.filter.orderNum = "";
      if (!this.filter.amountFrom) this.filter.amountFrom = "";
      if (!this.filter.amountTo) this.filter.amountTo = "";
      if (!this.filter.delivery_date) this.filter.delivery_date = "";


      this.$store.dispatch("order_module/fetchOrderList", {
        orderNum: this.filter.orderNum == null || undefined ? "" : this.filter.orderNum,
        amountFrom: this.filter.amountFrom == null || undefined ? "" : this.filter.amountFrom,
        amountTo: this.filter.amountTo == null || undefined ? "" : this.filter.amountTo,
        dateFrom: this.filter.dateFrom == null || undefined ? "" : this.filter.dateFrom,
        dateTo: this.filter.dateTo == null || undefined ? "" : this.filter.dateTo,
        statusItems: this.filter.select === null ? "" : this.filter.select.id,
        delivery_date: this.filter.delivery_date === null ? "" : this.filter.delivery_date,
        page: 1
      });
    },
    initFilter(){
        this.filter.select = this.$store.state.order_module.status
        this.filter.orderNum = this.$store.state.order_module.orderNum
        this.filter.amountFrom =this.$store.state.order_module.amountFrom,
        this.filter.amountTo = this.$store.state.order_module.amountTo,
        this.filter.dateFrom = this.$store.state.order_module.dateFrom,
        this.filter.dateTo = this.$store.state.order_module.dateTo
        this.filter.delivery_date = this.$store.state.order_module.delivery_date
    }
  },
  computed: {
    dateRangeText() {
      if (this.dates[0] !== "" && this.dates[0] !== "") {
        return this.dates.join(" ~ ");
      }
    },
    statusItems() {
      return [
        // { id: "1", text: this.$t("message.open") },
        { id: "2", text: this.$t("message.paid") },
        { id: "3", text: this.$t("message.ondelivery") },
        { id: "4", text: this.$t("message.onprogress") },
        // { id: "6", text: this.$t("message.onlocker") },
        { id: "5", text: this.$t("message.delivered") },
        { id: "6", text: this.$t("message.cancel") },
      ];
    },
  },
  created() {
    this.initFilter()
  },
};
</script>

<style>
.filter-input-class .v-input__slot .v-text-field__slot input{
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot .v-select__selections input {
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot input {
  padding: 8px 0px 8px 0px;
}
.font-size {
  font-size: 12px !important;
}
.btn-search {
  border-radius: 20px;
}
.v-input .v-input__control .v-text-field__details {
  /* centering text field filter order */
  /* display: none; */
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
.minus-amount {
  max-width: fit-content;
}
.minus-amount .icon-tilde-class {
  font-size: 15px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 8px;
}
</style>
